import { get, post, patch, del } from "./api.service";

const TimeClockService = {
  getJobs(params) {
    return get("user/job/search", params);
  },
  getUserJobs(params) {
    return get("user/job/user/search", params);
  },
  getGroupJobs(params) {
    return get("user/job/group/search", params);
  },
  getCategories(params) {
    return get("user/timeclock/category/search", params);
  },
  getCostCodes(params) {
    return get("user/cost/code/search", params);
  },
  getJobCostCodes(params) {
    return get("user/job/code/search", params);
  },
  getReasons(params) {
    return get("user/timeclock/reason/search", params);
  },
  getTimeClock(id) {
    return get("/user/timeclock", { id });
  },
  getTimeClockLatest() {
    return get("user/timeclock/latest");
  },
  getTimeClocks(params) {
    return get("/user/timeclock/search", params);
  },
  saveTimeClock(params) {
    const url = "user/timeclock";
    if (params.id) {
      return patch(url, params);
    } else {
      return post(url, params);
    }
  },
  getTimeClockNotes(params) {
    return get("user/timeclock/note/search", params);
  },
  saveTimeClockNote(params) {
    const url = "user/timeclock/note";
    if (params.id) {
      return patch(url, params);
    } else {
      return post(url, params);
    }
  },
  clockIn(clockIn) {
    return post("user/timeclock/clockin", clockIn);
  },
  clockOut(clockOut) {
    return post("user/timeclock/clockout", clockOut);
  },
  deleteTimeClock(params) {
    return del("user/timeclock", params);
  },
};

export { TimeClockService };
